import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from '../../utilities/axios';

const ProfileSlider = ({ userId }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`/sliders?UserId=${userId}`);

        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [userId]);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '20px',
    slidesToShow: 3,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    focusOnSelect: true,
    beforeChange: (current, next) => handleBeforeChange(current, next),
    afterChange: (current) => handleAfterChange(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '20px',
          speed: 4000,
          autoplay: true,
          autoplaySpeed: 2000,
          focusOnSelect: true,
          centerMode: true,
          infinite: true,
        },
      },
    ],
  };

  const handleBeforeChange = (current, next) => {
    const slides = document.querySelectorAll('.slick-slide');
    slides.forEach((slide) => {
      slide.classList.add('dim-slide');
    });
  };

  const handleAfterChange = (current) => {
    const slides = document.querySelectorAll('.slick-slide');
    slides.forEach((slide) => {
      slide.classList.remove('dim-slide');
    });
    const activeSlide = document.querySelector('.slick-center');
    if (activeSlide) {
      activeSlide.classList.remove('dim-slide');
    }
  };

  return (
    <div className='py-6 mt-4 overflow-x-hidden'>
      <Slider {...settings}>
        {images.length > 0 ? (
          images.map((slider, index) => (
            <div
              key={index}
              className='relative w-full flex items-center justify-center -px-2'>
              <img
                src={slider.image}
                alt={`Slide ${index + 1}`}
                className='w-full h-64 md:h-[400px] object-cover rounded-lg'
              />
            </div>
          ))
        ) : (
          <p>.</p>
        )}
      </Slider>
    </div>
  );
};

export default ProfileSlider;
